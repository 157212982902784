import * as React from "react";

const FormSaver: React.FC<any> = ({ children, saveFieldName,saveInfo, savePhoto, ...rest }) => {

  React.useEffect(() => {
    if (savePhoto) {
      const valuesToSave = { ...rest.values };
      if (valuesToSave.profilephoto) {
        const reader = new FileReader();
        reader.onload = () => {
          valuesToSave.profilephoto = reader.result;
          localStorage.setItem(saveFieldName, JSON.stringify(valuesToSave));
        };
        reader.readAsDataURL(valuesToSave.profilephoto);
      } 
      if(saveInfo){
        localStorage.setItem(saveFieldName, JSON.stringify(rest.values));
      }
    }
  }, [rest?.values,saveInfo]);

  return <>{children(rest)}</>;
};

export default FormSaver;