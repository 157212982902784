// Customizable Area Start
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IProfile,RoleStep } from "./Roles.types";
import React from "react";

export const configJSON = require("./config");
export const CURRENT_STATE_KEY = "currentState";
export interface Props {
  children: React.ReactNode;
}

interface S {
  currentUser: IProfile | null;
  loading: boolean;
  hangLoader: boolean;
  logoutModal: boolean;
  currentProfile:"Freelancer"|"Sponsor" ;
  sesstionTimeoutModal: boolean;
  switchAccountModal:boolean;
  showUnAuthorizeModal:boolean;
  showDeactivateModal:boolean;
  isOffline:boolean;
  showActivateModal: boolean;
  showDeleteModal: boolean;
  showAdminDeactiveModal: boolean;
}

interface SS {
  id: any;
}

export default class AuthProviderController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetCurrentUserCallId: string = "";
  availabilityApiCallId: string = "";
  availibility : boolean=false;
  call_count:number=0;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.ResetProfileMessage),
      getName(MessageEnum.UserLogoutMessage),
      getName(MessageEnum.GetCurrentUserMessage),
      getName(MessageEnum.SwitchAccountMessage),
      getName(MessageEnum.UnAuthorizeUserMessage),
      getName(MessageEnum.ChangeAvailibility),
    ];

    this.state = {
      currentUser: null,
      loading: false,
      hangLoader: false,
      logoutModal: false,
      currentProfile: "Freelancer",
      sesstionTimeoutModal: false,
      switchAccountModal:false,
      showUnAuthorizeModal:false,
      showDeactivateModal:false,
      isOffline:false,
      showActivateModal:false,
      showDeleteModal: false,
      showAdminDeactiveModal: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.ResetProfileMessage)) {
      this.getCurrentUser();
    }else if (message.id === getName(MessageEnum.UserLogoutMessage)) {
      this.handleLogoutModalOpen(true);
    }else if (message.id === getName(MessageEnum.GetCurrentUserMessage)) {
      this.sendLoginUserMessage();
    }else if (message.id === getName(MessageEnum.SwitchAccountMessage)) {
      this.openSwithAccountModal(true)
    }else if (message.id === getName(MessageEnum.UnAuthorizeUserMessage)) {
      setTimeout(() => {
        this.handleUnauthorizedMessage();
      }, 1000);
    }
    else if (message.id === getName(MessageEnum.ChangeAvailibility)) {
      const param = message.getData(getName(MessageEnum.AvailibilityBodyMessage));
      this.changeCurrentUserAvailibility(param)
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.apiGetCurrentUserCallId) {
        this.handleCurrentUserPayload(response);
      }else if (apiRequestCallId === this.availabilityApiCallId){
        this.setState((prev)=>{
          return {currentUser:prev.currentUser? {
            ...prev.currentUser,
            attributes:{
              ...prev.currentUser.attributes,availability:this.availibility
            }
          } : null}
        },()=>this.sendLoginUserMessage())
      }else {
        this.handleOtherResponse(response);
      }
    }
  }

  handleOtherResponse=async(response:any)=>{
    const publicEndpoints = [
      "ActivateAccount",
      "",
      "login",
      "Login",
      "SignUp",
      "signup"
    ];
    const currentPath = window.location.pathname;
    const isMatched = publicEndpoints.some(
      (item) => currentPath.split("/")[1].toLowerCase() === item.toLowerCase()
    );
    const loginToken = await getStorageData("loginSuccessToken")

    if(isMatched) {
      return;
    }

    if(!response){
      return;
    }
    if (response.errors && response.errors.length>0 && response.errors[0].token && loginToken)  {
      this.setState({
        sesstionTimeoutModal: !isMatched,
      });
    }else if (response.errors && response.errors.length>0 && response.errors[0].token && !loginToken)  {
      let userToBeRedirected = false;
      if (!isMatched) {
        userToBeRedirected = true;
        setStorageData("redirect_endpoint", currentPath);
      }
      window.location.href = "/";
    }else if(response.errors && response.errors.Blocked){
      this.setState({
        showDeactivateModal:true
      })
    }else if(response.errors && response.errors.Activation){
      this.setState({
      },()=>{
         this.getCurrentUser();
      })
    }
    else if(response.errors && response.errors.Deleted){
      this.setState({
        showDeleteModal:true
      })
    }
  }
  handleCurrentUserPayload=async(response:any)=>{
    this.call_count=0;
    this.setState({ loading: false });
    const user=response?.data as IProfile;
   
    if (user?.id) {
      this.handleCheckDeactivatedUser(user);
      const newState=await this.getCurrentState(user?.attributes?.role_name);
      this.setState(
        {
          currentUser: {
            ...user,
            currentState:newState
          },
          currentProfile:newState
        },
        () => {this.sendLoginUserMessage();this.changeCurrentUserAvailibility('true')}
      );
    }else if(response?.errors && response.errors.Deleted){
      this.setState({
        showDeleteModal:true
      })
    }
  }
  
  handleCheckDeactivatedUser=(user:any)=>{
    const currentLocation = window.location.href;

    if(currentLocation.includes("ActivateAccount")) {
      return;
    }

    if(user && user.attributes && user.attributes.admin_deactivated){
      this.setState({
        showAdminDeactiveModal:true
      })
    }else if(!user?.attributes?.account_detail?.data?.attributes?.activated){
      this.setState({
        showActivateModal:true
      })
    }else if(user.attributes && user.attributes.account_detail&& user.attributes.account_detail.data && user.attributes.account_detail.data.attributes && user.attributes.account_detail.data.attributes.blocked){
        this.setState({
          showDeactivateModal:true
        })
    }
  }
  isOnline=()=>{
    return navigator.onLine;
  }
  handleCheckInternetConnection=(relode=false,isOnline=true)=>{
    if(isOnline){
      this.setState({isOffline:false})
      if(relode){
        window.location.reload();
      }
    }else{
     this.setState({isOffline:true})
    }
  }
  handleOfflineOpen=(open:boolean)=>{
     this.setState({isOffline:open})
  }
  handleRecheckInternetConnection=()=>{
    return this.handleCheckInternetConnection(true,this.isOnline());
  }

  async componentDidMount() {
    window.addEventListener("beforeunload", this.handlebeforeload);
    this.getCurrentUser();
    this.handleCheckInternetConnection(false,this.isOnline());
    window.addEventListener("offline", this.handleOnlineOfflineEvent);
    window.addEventListener("online", this.handleOnlineOfflineEvent);
  }
  handleOnlineOfflineEvent = () => {
    this.handleCheckInternetConnection(true,this.isOnline());
  };

   async componentWillUnmount(): Promise<void> {
    window.removeEventListener("beforeunload", this.handlebeforeload);
    this.changeCurrentUserAvailibility("false")
  }

  handlebeforeload = () =>{this.changeCurrentUserAvailibility('false')}

  switchAccount = async () => {
    await setStorageData("switching", true);
    const { currentProfile } = this.state;
    const newProfile = currentProfile.toLowerCase().includes("freelancer") ? "Sponsor" : "Freelancer";
    await this.setCurrentState(newProfile);
    this.setState((prev) => {
      const prevUser = prev.currentUser as IProfile;
      return {
        currentUser: {
          ...prevUser,
          currentState: newProfile
        },
        currentProfile: newProfile,
        switchAccountModal: false,
        hangLoader: false
      };
    });
    const redirectPath = await this.handlePostRoleSwitchRedirection();
    window.location.href = redirectPath;
  };
  navigateToActualDashboard=()=>{
    const {currentProfile}=this.state;
    let newCheckoutPath="";
    if(currentProfile.toLowerCase().includes("freelancer")){
      newCheckoutPath="/FreelancerDashboard"
    }else{
      newCheckoutPath="/SponsorDashboard"
    }
    window.location.href = newCheckoutPath;
  }
  openSwithAccountModal=(open:boolean)=>{
    this.setState({switchAccountModal:open})
  }  
  openUnAuthorizeModal=(open:boolean)=>{
    this.setState({showUnAuthorizeModal:open})
  }

  handleNavigateToLogin=async()=>{
     await removeStorageData("loginSuccessToken");
     window.location.href = "/";
  }
  handleNavigateToSignUp=async()=>{
    await removeStorageData("loginSuccessToken");
     window.location.href = "/SignUp";
  }
  getCurrentState = async(apiState:RoleStep) => {
    let currentState=await getStorageData(CURRENT_STATE_KEY);
    if(!currentState){
      if(apiState?.toLowerCase().includes("both")){
        currentState="Sponsor";
        this.setCurrentState(currentState);
      }else{
        currentState=apiState?.toLowerCase().includes("freelancer")?"Freelancer":"Sponsor";
        this.setCurrentState(currentState);
      }
    }
    return currentState;
  };
  setCurrentState=async(state:RoleStep)=>{
    await setStorageData(CURRENT_STATE_KEY,state)
  }

  getCurrentUser = async () => {
    if(this.call_count>0){
      return;
    }
    this.call_count=1;
    this.setState({ loading: true });
    const userToken = localStorage.getItem("loginSuccessToken");
    const header = {
      "Content-Type": configJSON.jsonContentType,
      token: userToken,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getCurrentUserApiUrl}`,
      method: "GET",
      body: null,
    });
    this.apiGetCurrentUserCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changeCurrentUserAvailibility = async (params:string) => {
      if(params == "true"){
        this.availibility= true
      }    
      else{
        this.availibility= false
      }
    const formdata = new FormData();
    formdata.append("availability",params );
    const userToken = localStorage.getItem("loginSuccessToken");
    const header = {
      token: userToken,
    };
    const requestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.changeCurrentUserAvailibilityApiUrl}`,
      method: "POST",
      body: formdata,
    });
    this.availabilityApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendLoginUserMessage = () => {
    const user = this.state.currentUser;
    const msg: Message = new Message(getName(MessageEnum.LoginUserMessage));
    msg.addData(getName(MessageEnum.LoginUserBodyMessage), user);
    this.send(msg);
  };
  resetLoginUserProfile = () => {
    const msg: Message = new Message(getName(MessageEnum.ResetProfileMessage));
    msg.addData(getName(MessageEnum.ResetProfileBodyMessage), "reset_profile");
    this.send(msg);
  };

  handleLogoutModalOpen = (open = false) => {
    this.setState({ logoutModal: open });
  };
  handleLogout = () => {
    this.setState({
      logoutModal: false,
      sesstionTimeoutModal: false,
      hangLoader: true,
    });
    window.localStorage.clear();
    this.setState({
      logoutModal: false,
      currentUser: null,
    });
    window.location.href = "/";
  };

  async handleRedirection(queryRedirect: string | null, redirectEndpoint: string, newProfile: string) {
    if (queryRedirect) {
      const newUrl = window.location.origin + redirectEndpoint;
      window.history.pushState({}, '', newUrl);
    }
    const finalRedirectUrl = redirectEndpoint || 
      (newProfile === "Freelancer" ? "/FreelancerDashboard" : "/SponsorDashboard");
    window.location.href = finalRedirectUrl.startsWith('/') ? finalRedirectUrl : '/' + finalRedirectUrl;
    const pendingRedirect = await getStorageData("redirect_endpoint");
    if (pendingRedirect) {
      await removeStorageData("redirect_endpoint");
    }
  }
  
  handleUnauthorizedMessage = async () => {
    const switching = await getStorageData("switching");
    if(switching){
      return;
    }
    const currentUser = this.state.currentUser;
    const hasBothRoles = currentUser?.attributes?.role_name?.toLowerCase().includes('both');
    if (!hasBothRoles) {
      this.openUnAuthorizeModal(true);
      return;
    }
    const newProfile = this.state.currentProfile === "Freelancer" ? "Sponsor" : "Freelancer";
    const queryRedirect = await getStorageData("redirect_endpoint");
    const pathRedirect = window.location.pathname;
    const redirectEndpoint = queryRedirect || pathRedirect;
    await this.setCurrentState(newProfile);
    this.setState((prev) => {
      const prevUser = prev.currentUser as IProfile;
      return {
        currentUser: {
          ...prevUser,
          currentState: newProfile
        },
        currentProfile: newProfile,
        hangLoader: true
      };
    }, async () => {
      await this.handleRedirection(queryRedirect, redirectEndpoint, newProfile);
    });
  };

  handlePostRoleSwitchRedirection = async () => {
    const swtiching = await getStorageData("switching");
    if(swtiching){
      await removeStorageData("switching");
    }
    const pendingRedirect = await getStorageData("redirect_endpoint");
    if (pendingRedirect) {
      await removeStorageData("redirect_endpoint");
    }
    return this.state.currentProfile === "Freelancer" ? 
      "/FreelancerDashboard" : 
      "/SponsorDashboard";
  };
}

// Customizable Area End