import React from 'react'
import MyBidController , { configJSON } from './MyBidProjectDetailsController.web';
import "./MyBid.css";
import { Box, Typography,IconButton,Grid ,Tooltip} from "@material-ui/core";
import { leftArrow } from "./assets";
import FreelancerHeader from '../../dashboard/src/FreelancerHeader.web';
import DashboardFooter from '../../dashboard/src/DashboardFooter.web';
import MyBidNegotition from './MyBidNegotition.web';
import AboutSponsor from '../../../components/src/my-bid/AboutSponsor.web'
import FullPageBackdropLoader from '../../../components/src/utill/FullPageBackdropLoader.web';
import { IProfile } from '../../rolesandpermissions/src/Roles.types';

const getCostDisplayElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean) => {
    return (
      <>
        {sponsorCost?.currency_symbol} {sponsorCost?.amount} {"   "}
        {  sponsorCost?.currency != freelancerCost?.currency &&
      <Tooltip enterTouchDelay={0} leaveTouchDelay={3000}  title={`Converted from (${sponsorCost.currency}) into (${freelancerCost.currency}) based on today's conversion rate`}>
        <span style={{color:"#EC952D" , fontWeight:400, fontSize:"12px"}} >({freelancerCost?.currency_symbol}{freelancerCost?.amount})</span>
      </Tooltip> }
        {hourlyRated && " /hr"}
      </>
    )
  } 
export default class MyBidProjectDetails extends MyBidController {
    render() {
        const {getBidLoading,currentBids,project}=this.state;
        const isFixedPrice =project?.attributes?.budget_type==="fixed_price";
        const projectType=project?.attributes?.budget_type==="hourly_price"?"Hourly Rated":"Fixed Price";
        const eastimatedTime=project?.attributes?.EST_time || 0;
        const convertedAmount=project?.attributes?.currency_conversion;
        const convertedBidAmount=currentBids?.attributes?.currency_conversion;
        const estimatedBudget=getCostDisplayElement(convertedAmount?.freelancer,convertedAmount?.sponsor,!isFixedPrice);
        const submittedBidAmount=getCostDisplayElement(convertedBidAmount?.freelancer,convertedBidAmount?.sponsor,!isFixedPrice);
        return (
            <>
                <FullPageBackdropLoader open={getBidLoading}/>
                <FreelancerHeader
                    navigation={this.props.navigation}
                    history={undefined}
                    location={undefined}
                    id={""}
                    route={""}
                />
                {
                    currentBids!==null && project!==null && (
                        <Box className="body_wrapper">
                             <Box className="projects_filter_wrapper">
                                <Box className="project_container">
                                    <Box className='bid_negititation'>
                                        <IconButton style={{marginLeft:"-13px"}} data-testid="goBackBtn" onClick={this.goBack}>
                                          <img src={leftArrow} alt="image" className='leftarrow_img'/>
                                        </IconButton>
                                        <span style={{color:"black",fontWeight:"bolder"}} className="company_logo_container">
                                           {project?.attributes?.project_name}
                                        </span>
                                    </Box>
                                    <Box className="price_negotiation">
                                        <span style={{marginRight:"15px"}}>{projectType}</span>
                                        <span>Est. Time:{ " "}{eastimatedTime} </span>
                                        <span>{project?.attributes?.posted_at}</span>
                                    </Box>

                                    <Box className="skillsset_wrapper">
                                        {
                                           project?.attributes?.skills?.map((skill)=>(
                                            <Box key={skill?.id} className="skill_set_requirement">{skill?.name}</Box>
                                           ))
                                        }
                                    </Box>

                                    <Typography style={webStyle.textWrapper} className="text_pera">
                                      {
                                        project?.attributes?.description
                                      }
                                    </Typography>

                                    <Grid container className="bid_negititation">
                                        <Grid item xs="auto" sm={3} className="price_negotiation_outer_wrapper">
                                            <Grid className="price_negotiation">{configJSON.cardLabelEstimate}</Grid>
                                            <Grid style={{color:"black"}} className="price_cantainer">{estimatedBudget}</Grid>
                                        </Grid>
                                        <Grid item xs="auto" sm={3} className="price_negotiation_outer_wrapper">
                                            <Grid className="price_negotiation">{configJSON.cardLabelDuration}</Grid>
                                            <Grid style={{color:"black"}} className="price_cantainer">
                                            {project?.attributes?.project_size} {" "} {project?.attributes.select_duration_type}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs="auto" sm={2} className="price_negotiation_outer_wrapper">
                                            <Grid className="price_negotiation">{configJSON.cardLabelExperience}</Grid>
                                            <Grid style={{color:"black"}} className="price_cantainer">{project?.attributes.expected_experience}</Grid>
                                        </Grid>
                                        <Grid item xs="auto" sm={2} className="price_negotiation_outer_wrapper">
                                            <Grid className="price_negotiation">{configJSON.cardLabelLanguage}</Grid>
                                            <Grid style={{color:"black"}} className="price_cantainer">
                                              {project.attributes.language_of_converse}
                                            </Grid>
                                        </Grid>
                                        {
                                            project.attributes.bid_status === 0 &&
                                            (
                                                <Grid item xs="auto" sm={2} className="price_negotiation_outer_wrapper">
                                                    <Grid className="price_negotiation">{configJSON.cardLabelCurrentBids}</Grid>
                                                    <Grid style={{color:"black"}} className="price_cantainer">{project?.attributes?.number_of_bidders - project?.attributes?.number_of_propsals}</Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Box>
                            </Box>
    
                        {/* Area of cover later  */}
                        <Box className="personal-details-wrapper">
                            <Box className="devloper_coverlatter bck-clr">
                                <p style={{color:"black"}} className="company_logo_container">Proposal Details</p>
                                <Box className="dev_details">
                                    {currentBids.attributes.project.bid_status != "non_bid_project" &&<Box className="price_negotiation_outer_wrapper">
                                        <Box className="price_negotiation">Submitted Quote</Box>
                                        <Box style={{color:"black"}} className="price_cantainer">{submittedBidAmount}</Box>
                                    </Box>}
                                    <Box className="price_negotiation_outer_wrapper reduce_margin">
                                        <Box className="price_negotiation">Proposed Duration</Box>
                                        <Box style={{color:"black"}} className="price_cantainer">{currentBids && currentBids.attributes && currentBids.attributes.total_amount_of_time_in_days} days</Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="My-cv-latter">
                                <h2 style={{color:"black"}} className="freelancer_resume">Cover Letter</h2>
                                <Typography style={webStyle.textWrapper} className="text_container">
                                    {currentBids?.attributes?.proposal}
                                </Typography>
                            </Box>
                        </Box>
                        
                        <MyBidNegotition 
                            bid={currentBids}
                            currentUser={this.state.currentUser as IProfile}
                            navigation={this.props.navigation}
                            project={project}
                            relodeBid={this.getBidInfo}
                        />
                        <AboutSponsor
                                name={currentBids.attributes.sponsor.profile.full_name}
                                city={currentBids.attributes.sponsor.city}
                                country={currentBids.attributes.sponsor.country}
                                rating={currentBids.attributes.sponsor.profile.rating}                                 
                                totalRatingCount={currentBids.attributes.sponsor.total_reviews}
                                id = {currentBids.attributes.sponsor.profile.id}    
                                profileImage={project.attributes.profile_image}              
                        />
                    </Box>
                    )
                }
             
                <DashboardFooter
                    navigation={undefined}
                    history={undefined}
                    location={undefined}
                />
            </>
        );
    }
}
const webStyle = {
    textWrapper: {
        color:"#222", 
        whiteSpace: 'pre-wrap' as const,
        wordBreak: 'break-word' as const,}
}