import React from 'react';
import {Typography} from '@material-ui/core';
import { useAuth } from '../../../blocks/rolesandpermissions/src/AuthContext.web';
import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';

export const replaceBidToQuote=(value:string="")=>{
    return value?.replace("Bid","Quote");
}
const getLocalTime = (timeZone: string, time: string) => {
    const dateObj = new Date(time);
    const options: any = {
        timeZone,
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };
    try {
        const formatter = new Intl.DateTimeFormat("en-US", options);
        const formattedTime = formatter.format(dateObj);
        return formattedTime;
    } catch (err) {
        delete options.timeZone;
        const formatter = new Intl.DateTimeFormat("en-US", options);
        const formattedTime = formatter.format(dateObj);
        return formattedTime;
    }
};

interface Props{
    date:string;
    style?:any;
    className?:string;
}
export const TimeOnly:React.FC<Props>=({date,style={},className=""})=>{
    const currentUser=useAuth();
    const timeZone=currentUser?.user?.attributes?.time_zone || '';
    const time=getLocalTime(timeZone,date);
    return(
        <Typography className={className} style={{
            color: "#8083a3",
            fontSize: '10px',
            fontWeight: 500,
            ...style
        }}>
            {time}
        </Typography>
    )
}

export const isValidPhoneNumberForCountry = (
    phoneNumberString: string,
    country: CountryCode
  ): any => {
    try {
      const phoneNumber = parsePhoneNumberWithError(phoneNumberString, country);
  
      if (!phoneNumber) {
        return { isValid: false, error: 'Phone number format is not right' };
      }
  
      if (phoneNumber.country !== country) {
        return { isValid: false, error: 'Phone number does not match the selected country' };
      }
  
      if (!phoneNumber.isValid()) {
        return { isValid: false, error: 'Invalid phone number' };
      }
  
      const phoneLength = phoneNumber.nationalNumber.toString().length;
      if (phoneLength !== 10 && country === "IN") {
        return { isValid: false, error: 'Invalid phone number length' };
      } else if (phoneLength < 10 || phoneLength > 18) {
        return { isValid: false, error: 'Invalid phone number length' };
      }
  
      return { isValid: true, error: '' };
    } catch (error) {
      console.error('Phone number validation error:', error);
      return { isValid: false, error: 'Invalid phone number format' };
    }
  }